<template lang="pug">
div.d-flex.flex-column
  HeaderGrid(:name="name" :uid="uid" userFilter="paymentFilters" :dateRanger="dateRanger" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
  StatCards
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
        .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
              span.card-list-stats-title-text TOUT
            .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
          .card-sum
            | {{ getSumDataByStatus("all") }}
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('credit')")
        .card-list-stats.border-success(:class="tabActive == 'credit' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-success.mr-50 add
              span.card-list-stats-title-text Crédit
            .card-list-stats-title-badge.bg-success.text-white {{ getCountDataByStatus("credit") }}
          .card-sum
            | {{ getSumDataByStatus("credit") }}
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('debit')")
        .card-list-stats.border-danger(:class="tabActive == 'debit' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-danger.mr-50 remove
              span.card-list-stats-title-text Débit
            .card-list-stats-title-badge.bg-danger.text-white {{ getCountDataByStatus("debit") }}
          .card-sum
            | {{ getSumDataByStatus("debit") }}
            span {{ tvaMode }}
      div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('remainsToBeDeducted')")
        .card-list-stats.border-yellow(:class="tabActive == 'remainsToBeDeducted' ? 'current' : ''")
          .card-list-stats-title
            div
              span.card-list-stats-title-badge.material-icons.text-yellow.mr-50 add_card
              span.card-list-stats-title-text Reste à solder
            .card-list-stats-title-badge.bg-yellow.text-white {{ getCountDataByStatus("remainsToBeDeducted") }}
          .card-sum
            | {{ getSumDataByStatus("remainsToBeDeducted") }}
            span {{ tvaMode }}
  .d-flex.w-100.h-100(style="overflow: hidden;")
    .text-center.flex-center.w-100(v-if='isLoadingPaymentsList || !headerLoaded')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement de la liste des règlements...
    sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" :sortOptions="sortOptions")
  ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
    sidebar-payment-content(ref='sidebarPaymentContent' @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";

import SidebarPaymentContent from "@/components/invoice/SidebarPaymentContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarPaymentContent,
    SyncGrid,
    StatCards,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      homepageMode: true,
      homepage: false,
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "payment",
      uid: "payments",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      tvaMode: "TTC",
      dateRanger: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: false,
      allowGrouping: true,
      searchPlaceholder: "Rechercher un règlement",
      sortOptions: { columns: [{ field: "date", direction: "Descending" }] },
    };
  },
  computed: {
    ...mapGetters([
      "paymentsList",
      "isLoadingPaymentsList",
      "institutionsList",
      "workspaceSelected",
      "institutionSelected",
      "userParametersList",
    ]),
    dataOrigine() {
      return this.$store.getters[this.name + "sList"];
    },
    dataFiltered() {
      if (this.tabActive == "all") {
        return this.$store.getters[this.name + "sList"];
      } else if (this.tabActive == "debit") {
        return this.$store.getters[this.name + "sList"].filter(
          (elem) => elem.paymentType == 0
        );
      } else if (this.tabActive == "credit") {
        return this.$store.getters[this.name + "sList"].filter(
          (elem) => elem.paymentType == 1
        );
      } else {
        return this.$store.getters[this.name + "sList"].filter(
          (elem) => elem.remainsToBeDeducted > 0
        );
      }
    },
  },
  async created() {
    await this.getCompanies({});
    await this.getCollaborators({});
  },
  async activated() {
    let filter = this.userParametersList.find(
      (el) => el.key == "paymentFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions(["getCompanies", "getCollaborators", "getPayments"]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },

    async changeTab(tab) {
      this.tabActive = tab;
    },
    toggleModal() {
      this.selectInstitution = this.institutionSelected;
      this.$refs["modal-new-payment"].toggle("#toggle-btn-new-payment");
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getPayments({
          startTo: dateRange.startDate,
          endTo: dateRange.endDate,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    async setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
        },
        {
          type: "string",
          field: "paymentType",
          filterTemplate: "paymentTypeFilterTemplate",
          headerText: "Sens",
          width: 140,
          maxWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          template: "paymentTypeTemplate",
          visible: true,
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "date",
          headerText: "Date",
          width: 195,
          maxWidth: 195,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "companyName",
          headerText: "Client",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "paymentMethod.label",
          headerText: "Mode de paiement",
          width: 180,
          minWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "bankAccount.label",
          headerText: "Banque",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "date",
          format: "formatDateOptions",
          field: "remittedBankDate",
          headerText: "Remis en banque",
          width: 195,
          maxWidth: 195,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          // allowGrouping: false
        },
        {
          type: "number",
          field: "amount",
          headerText: "Montant",
          maxWidth: 150,
          textAlign: "right",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          format: "formatCurrency",
          allowGrouping: false,
        },
        {
          type: "number",
          field: "remainsToBeDeducted",
          headerText: "Reste à solder",
          maxWidth: 150,
          textAlign: "right",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          format: "formatCurrency",
          showFooterSum: true,
          allowGrouping: false,
        },
      ];
    },
    clickAction() {
      this.$router.push({ path: "/new-payment" });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else if (status == "debit") {
        return this.dataOrigine.filter((elem) => elem.paymentType == 0).length;
      } else if (status == "credit") {
        return this.dataOrigine.filter((elem) => elem.paymentType == 1).length;
      } else {
        return this.dataOrigine.filter((elem) => elem.remainsToBeDeducted > 0)
          .length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          sum += obj["amount"];
        });
      } else if (status == "debit") {
        this.dataOrigine
          .filter((elem) => elem.paymentType == 0)
          .forEach((obj) => {
            sum += obj["amount"];
          });
      } else if (status == "credit") {
        this.dataOrigine
          .filter((elem) => elem.paymentType == 1)
          .forEach((obj) => {
            sum += obj["amount"];
          });
      } else {
        this.dataOrigine
          .filter((elem) => elem.remainsToBeDeducted > 0)
          .forEach((obj) => {
            sum += obj["remainsToBeDeducted"];
          });
      }
      return this.formatCurrency(sum);
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarPaymentContent"].getPaymentDetails(event.rowData.id);
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    setTagsSelected(val) {},
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
