<template>
  <div class="h-100">
    <div v-if="isLoadingPayment" class="h-100">
      <div class="text-center flex-center h-100">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">{{ loadingTitle }}</span>
        </div>
        <div style="margin-top: 7rem">
          {{ loadingMessage }}
        </div>
      </div>
    </div>
    <div v-else class="h-100">
      <div class="sidebar-header py-1 px-2">
        <div>
          <h3>Détail du règlement</h3>
        </div>
        <feather-icon
          icon="XIcon"
          size="24"
          @click.stop="closeSidebar"
          class="close-icon cursor-pointer"
        >
        </feather-icon>
      </div>
      <vs-divider class="m-0" />

      <div class="sidebar-action py-05 px-2">
        <div class="d-flex align-items-center justify-content-between w-100">
          <b-button-group>
            <!-- POSSIBILITE DE MODIFIER UNIQUEMENT LA REMISE EN BANQUE -->
            <b-button
              class="btn-icon d-flex"
              variant="primary"
              @click="editPayment(payment.id, true)"
              :style="
                payment.remainsToBeDeducted > 0
                  ? 'border-right:1px solid #ccc !important;'
                  : ''
              "
            >
              <feather-icon icon="EditIcon" /><span style="margin-left: 3px"
                >Modifier</span
              >
            </b-button>
            <!-- POSSIBILITE D'AJOUTER UN DOCUMENT (FACTURE, AVOIR) MAIS SANS TOUCHER AUX ANCIENS REGLEMENT ET SANS MODIFIER LES INFORMATIONS DE REGLEMENT -->
            <b-button
              v-if="payment.remainsToBeDeducted > 0"
              @click="editPayment(payment.id, true)"
              style="border-left: 1px solid #ccc !important"
              class="btn-icon d-flex"
              variant="primary"
            >
              <feather-icon icon="FileMinusIcon" /><span
                style="margin-left: 3px"
                >Solder</span
              >
            </b-button>
          </b-button-group>
          <div class="d-flex align-items-center justify-end">
            <!-- POSSIBILITE DE SUPPRIMER UN REGLEMENT UNIQUEMENT S'IL N'EST PAS LIE A UN DOCUMENT (FACTURE, AVOIR) -->
            <b-button
              v-if="!haveDocumentLinked"
              variant="outline-danger"
              class="btn-icon"
              @click="deletePaymentLocal(payment.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </div>
      </div>

      <vs-divider class="m-0" />

      <div class="sidebar-content">
        <div>
          <h4 class="mb-1 text-primary d-flex justify-content-start">
            <span style="min-width: min-content">
              <feather-icon icon="ChevronRightIcon" /> Informations
            </span>
          </h4>
          <ul class="listTab">
            <li>
              <span>Type</span>
              <span>{{ payment.paymentType == 0 ? "Débit" : "Crédit" }}</span>
            </li>
            <li>
              <span>Client</span>
              <span>{{ payment.companyName }}</span>
            </li>
            <li>
              <span>Date</span>
              <span>{{ formatDate(payment.date) }}</span>
            </li>
            <li>
              <span>Moyen de paiement</span>
              <span>{{ paymentMethodTranslate(payment.paymentMethodId) }}</span>
            </li>
            <li>
              <span>Montant</span>
              <span>{{ formatCurrency(payment.amount) }}</span>
            </li>
            <li>
              <span>Reste à solder</span>
              <span>{{ formatCurrency(payment.remainsToBeDeducted) }}</span>
            </li>
            <li v-if="payment.reference">
              <span>Référence</span>
              <span>{{ payment.reference }}</span>
            </li>
          </ul>
          <vs-divider
            v-if="payment.remittedBank || haveDocumentLinked || payment.notes"
          />
        </div>
        <div v-if="payment.remittedBank">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="DownloadIcon" /> Remise en banque
          </h4>
          <ul class="listTab">
            <li v-if="payment.remittedBankDate">
              <span>Date</span>
              <span>{{ formatDate(payment.remittedBankDate) }}</span>
            </li>
            <li v-if="payment.bankAccountId">
              <span>Compte bancaire</span>
              <span>{{ payment.bankAccountId }}</span>
            </li>
          </ul>
          <vs-divider v-if="haveDocumentLinked || payment.notes" />
        </div>
        <div v-if="haveDocumentLinked">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="FileIcon" />
            {{
              payment.invoices.length > 1 || payment.credits.length > 1
                ? "Documents liés"
                : "Document lié"
            }}
          </h4>
          <div v-if="payment.invoices.length > 0">
            <div class="mb-1" v-for="(item, index) in payment.invoices">
              <span
                >{{ natureShortTranslate(item.nature) }}
                {{ item.documentReference }} - Montant soldé:
                {{ formatCurrency(item.value) }}</span
              >
              <feather-icon
                @click="
                  displayWargingMessageBeforeUnlinkDocument(
                    [item.id],
                    item.value,
                    1,
                    'invoice'
                  )
                "
                class="cursor-pointer ml-1"
                icon="XIcon"
                size="18"
              />
            </div>
          </div>
          <div v-if="payment.credits.length > 0">
            <div clas="mb-1" v-for="(item, index) in payment.credits">
              <span
                >{{ natureShortTranslate(item.nature) }}
                {{ item.documentReference }} - Montant soldé:
                {{ formatCurrency(item.value) }}</span
              >
              <feather-icon
                @click="
                  displayWargingMessageBeforeUnlinkDocument(
                    [item.id],
                    item.value,
                    0,
                    'credit'
                  )
                "
                class="cursor-pointer ml-1"
                icon="XIcon"
                size="18"
              />
            </div>
          </div>
          <div v-if="payment.holdbacks.length > 0">
            <div clas="mb-1" v-for="(item, index) in payment.holdbacks">
              <span
                >Retenue de garantie de la facture
                {{ natureShortTranslate(item.nature) }}
                {{ item.documentReference }} - Montant soldé:
                {{ formatCurrency(item.value) }}</span
              >
              <feather-icon
                @click="
                  displayWargingMessageBeforeUnlinkDocument(
                    [item.id],
                    item.value,
                    0,
                    'holdback'
                  )
                "
                class="cursor-pointer ml-1"
                icon="XIcon"
                size="18"
              />
            </div>
          </div>
          <vs-divider v-if="payment.notes" />
        </div>
        <div v-if="payment.notes">
          <h4 class="mb-1 text-primary">
            <feather-icon icon="FileTextIcon" /> Notes
          </h4>
          <p class="paraphbox">
            <span>{{ payment.notes }}</span>
          </p>
          <vs-divider />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ENatureShort } from "@/types/api-orisis/enums/enums";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import {
  formatCurrency,
  formatDate,
} from "@/types/api-orisis/library/FormatOperations.ts";
import {
  natureShortTranslate,
  paymentMethodTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  data() {
    return {
      loadingTitle: "Chargement",
      loadingMessage: "Chargement des détails du paiment...",
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingPayment",
      "natureList",
      "payment",
      "paymentMethodsList",
    ]),
    haveDocumentLinked() {
      return (
        (this.payment.invoices ||
          this.payment.credits ||
          this.payment.holdbacks) &&
        (this.payment.invoices.length > 0 ||
          this.payment.credits.length > 0 ||
          this.payment.holdbacks.length > 0)
      );
    },
  },
  methods: {
    ...mapActions([
      "getPaymentById",
      "unlinkInvoicePayment",
      "unlinkCreditPayment",
      "unlinkHoldbackPayment",
      "deletePayment",
    ]),
    formatCurrency,
    formatDate,
    natureShortTranslate,
    paymentMethodTranslate,
    displayWargingMessageBeforeUnlinkDocument(ids, amount, type, nature) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title:
            "Êtes-vous sûr de vouloir annuler le règlement de " +
            (type == 1 ? "cette facture ?" : "cet avoir ?"),
          size: "sm",
          okVariant: "danger",
          okTitle: "Oui",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value && nature == "invoice") {
            this.unlinkInvoicesPaymentLocal(ids, amount);
          } else if (value && nature == "credit") {
            this.unlinkCreditsPaymentLocal(ids, amount);
          } else if (value && nature == "holdback") {
            this.unlinkHoldbacksPaymentLocal(ids, amount);
          }
        });
    },
    unlinkInvoicesPaymentLocal(invoiceIds = [], amount) {
      this.unlinkInvoicePayment({
        payment: this.payment,
        invoiceIds: invoiceIds,
        amount: amount,
      }).then(() => {
        this.$emit("refreshGrid");
      });
    },
    unlinkHoldbacksPaymentLocal(holdbackIds = [], amount) {
      this.unlinkHoldbackPayment({
        payment: this.payment,
        holdbackIds: holdbackIds,
        amount: amount,
      }).then(() => {
        this.$emit("refreshGrid");
      });
    },
    unlinkCreditsPaymentLocal(creditIds = [], amount) {
      this.unlinkCreditPayment({
        payment: this.payment,
        creditIds: creditIds,
        amount: amount,
      }).then(() => {
        this.$emit("refreshGrid");
      });
    },
    async getPaymentDetails(id) {
      if (id != "") {
        this.getPaymentById({
          paymentId: id,
        });
      } else {
        this.$store.commit("SET_PAYMENT", {});
      }
    },
    async editPayment(id, disabled) {
      await this.getPaymentById({
        paymentId: id,
      });

      if (this.payment.holdbacks && this.payment.holdbacks.length > 0) {
        this.closeSidebar();
        this.$router.push({
          name: "edit-payment-holdback",
          params: {
            id: id,
            disabled: disabled,
            documentData: structuredClone(this.payment),
            title: "Edit règlement ",
            tips: "Editer un règlement",
            routeOrigine: "payments",
          },
        });
      } else {
        // type == 1 => remise en banque
        // type == 2 => solder le règlement
        this.closeSidebar();
        this.$router.push({
          name: "edit-payment",
          params: {
            id: id,
            disabled: disabled,
            documentData: null,
            title: "Edit règlement ",
            tips: "Editer un règlement",
            routeOrigine: "payments",
          },
        });
      }
    },
    deletePaymentLocal(id) {
      this.$bvModal
        .msgBoxConfirm("Cette action est définitive et irréversible.", {
          title: "Êtes-vous sûr de vouloir supprimer le règlement ?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Supprimer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-primary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deletePayment({
              paymentIds: [id],
            });
            this.closeSidebar();
            this.$emit("refreshGrid");
          }
        });
    },
    closeSidebar() {
      this.$emit("closeSidebar");
    },
  },
  mounted() {
    (this.loadingTitle = "Chargement"),
      (this.loadingMessage = "Chargement des détails du règlement...");
  },
  components: {
    VuePerfectScrollbar,
  },
};
</script>

<style lang="scss" scoped>
.sidebar-action {
  flex-direction: column;
}
</style>
